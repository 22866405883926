import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import { themeDark, themeLight } from "./theme";
const getTheme = (queryList: { matches: boolean }) => {
  const prefersDarkMode = queryList.matches;
  return prefersDarkMode ? themeDark : themeLight;
};
function ThemeProviderFix({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState(
    getTheme(window.matchMedia("(prefers-color-scheme: dark)"))
  );
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        setTheme(getTheme(e));
      });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default ThemeProviderFix;
