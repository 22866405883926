import { ThemeOptions, createTheme } from "@mui/material/styles";

const themeOptionsLight: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#e57373",
    },
    secondary: {
      main: "#93c6ff",
    },
  },
};
const themeOptionsDark: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#e57373",
    },
    secondary: {
      main: "#93c6ff",
    },
  },
};
export const themeLight = createTheme(themeOptionsLight);

export const themeDark = createTheme(themeOptionsDark);
