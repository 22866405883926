import * as React from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from "@mui/material/ToggleButtonGroup";
import { i18n } from "@lingui/core";
export default function LanguageControl({
  style,
}: {
  style?: React.CSSProperties;
}) {
  const [language, setLanguage] = React.useState("lt");

  React.useEffect(() => {
    setLanguage(localStorage.getItem("language") || "lt");
  }, []);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: string
  ) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
    }
  };
  React.useEffect(() => {
    i18n.activate(language);
    localStorage.setItem("language", language);
  }, [language]);
  const children = [
    <ToggleButton
      style={{ color: language === "lt" ? "" : "white" }}
      value="lt"
      key="lt"
    >
      LT
    </ToggleButton>,
    <ToggleButton
      style={{ color: language === "en" ? "" : "white" }}
      value="en"
      key="en"
    >
      EN
    </ToggleButton>,
    <ToggleButton
      style={{ color: language === "ru" ? "" : "white" }}
      value="ru"
      key="ru"
    >
      RU
    </ToggleButton>,
  ];

  const control: ToggleButtonGroupProps = {
    color: "secondary",
    value: language,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <ToggleButtonGroup style={style} {...control} aria-label="Medium sizes">
      {children}
    </ToggleButtonGroup>
  );
}
