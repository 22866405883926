import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Slider from "../mui-image-slider/Slider";
import { ReactElement } from "react";

type ActionAreaCardProps = {
  images: string[];
  title: ReactElement<any, any>;
  description: ReactElement<any, any>;
  large?: boolean;
};
export default function ActionAreaCard({
  images,
  title,
  description,
  large,
}: ActionAreaCardProps) {
  return (
    <Card sx={{ maxWidth: "100%" }}>
      <Slider large={large} images={images}></Slider>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
