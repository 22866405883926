import React, { useEffect } from "react";
import logo from "../../img/logo.png";
import { useGlobalStore } from "../../store/store";
import "./Hero.css";
import { Trans } from "@lingui/macro";
import { Language } from "@mui/icons-material";
import LanguageControl from "../language/LanguageControl";
import useMediaQuery from "@mui/material/useMediaQuery";

function Hero() {
  const matches = useMediaQuery("(max-width:600px)");
  const [gone, setGone] = React.useState(false);
  const [small, setSmall] = React.useState(false);
  const setLogoReady = useGlobalStore((state) => state.setLogoReady);
  useEffect(() => {
    const image = document.getElementById("logo");
    onscroll = () => {
      const quarter = window.innerHeight / 4 - 65;
      const fromTop = window.scrollY;
      const imageTop = image!.offsetTop; // Distance of the image from the top of the document
      if (fromTop >= quarter) {
        setSmall(true);
      } else {
        setSmall(false);
      }
      if (fromTop >= imageTop - (matches ? 10 : 15)) {
        setLogoReady(true);
        setGone(true);
      } else {
        setGone(false);
        setLogoReady(false);
      }
    };
  });
  const makeLogoClass = () => {
    let name = "logo";
    if (small) {
      name += " logoSmall";
    }
    if (gone) {
      name += " logoGone";
    }
    return name;
  };
  return (
    <div id="Home" className="hero">
      <div
        style={{
          position: "relative",
          background:
            "linear-gradient(rgba(0, 0, 0, 0.4), var(--brand-red-transparent)",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexGrow: 0.5,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "column",
            flexBasis: "flex-end",
          }}
        >
          <img id="logo" className={makeLogoClass()} src={logo} alt="logo" />
          <p
            style={{
              textAlign: "center",
              color: "white",
            }}
          >
            <Trans>A small house near the Baltic sea-side</Trans>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
