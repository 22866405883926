import { ButtonGroup, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import "./AppBarTop.css";

import logo from "../../img/logo.png";
import { useGlobalStore } from "../../store/store";
import { Trans } from "@lingui/macro";
import LanguageControl from "../language/LanguageControl";
export default function AppBarTop() {
  const matches = useMediaQuery("(max-width:600px)");

  const overEight = useMediaQuery("(min-width:800px)");
  const logoReady = useGlobalStore((state) => state.logoReadyForAppBar);
  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      const headerHeight = matches ? 55 : 65;
      window.scrollTo({
        top: section.offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  };
  const getTop = (logoReady: boolean) => {
    if (logoReady && !overEight) return "-55px";
    if (overEight) {
      return "0px";
    } else {
      return matches ? "45px" : "55px";
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <div className={"AppBar" + (logoReady ? " Ready" : "")}>
        <Toolbar
          style={{
            width: "100%",
            padding: "0",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
            }}
          >
            <img
              onClick={() => {
                if (logoReady) scrollToSection("Home");
              }}
              className={"AppBarLogo" + (logoReady ? " Ready" : "")}
              src={logo}
              alt="logo"
            />
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button
                size={matches ? "small" : "medium"}
                color="inherit"
                onClick={() => scrollToSection("Rooms")}
              >
                <Trans>Rooms</Trans>
              </Button>
              <Button
                size={matches ? "small" : "medium"}
                color="inherit"
                onClick={() => scrollToSection("About")}
              >
                <Trans>About</Trans>
              </Button>
              <Button
                size={matches ? "small" : "medium"}
                variant="contained"
                onClick={() => scrollToSection("Contact")}
              >
                <Trans>Contact</Trans>
              </Button>
            </ButtonGroup>

            <LanguageControl
              style={{
                position: "absolute",
                transition: "all 0.5s",
                top: getTop(logoReady),
                opacity: logoReady ? (overEight ? "1" : "0") : "1",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
          </Box>
        </Toolbar>
      </div>
    </Box>
  );
}
