import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import VilaMares from "./VilaMares";
import reportWebVitals from "./reportWebVitals";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { messages as enMessages } from "./locales/en/text";
import { messages as ltMessages } from "./locales/lt/text";
import { messages as ruMessages } from "./locales/ru/text";
const root = document.getElementById("root");

i18n.load({
  en: enMessages,
  lt: ltMessages,
  ru: ruMessages,
});

i18n.activate("lt");
const App = () => (
  <I18nProvider i18n={i18n}>
    <VilaMares />
  </I18nProvider>
);
ReactDOM.render(<App />, root);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
