// zustand setore
import { create } from "zustand";

type GlobalState = {
  logoReadyForAppBar: boolean;
  setLogoReady: (isReady: boolean) => void;
  closeImageViewer: () => void;
  openImageViewer: (images: string[], index?: number) => void;
  modalState: { images: string[]; initialIndex: number } | undefined;
};

export const useGlobalStore = create<GlobalState>((set) => ({
  logoReadyForAppBar: false,
  setLogoReady: (isReady) => set({ logoReadyForAppBar: isReady }),
  closeImageViewer: () => {
    set({ modalState: undefined });
  },
  openImageViewer: (images: string[], index: number = 0) => {
    window.history.pushState({ imageViewer: true }, "", "/");
    set({ modalState: { images, initialIndex: index } });
  },
  modalState: undefined,
}));
