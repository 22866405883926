import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import catalog from "../../img/images";
import ActionAreaCard from "../card/ActionAreaCard";
import { Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
function Rooms() {
  const padding = 1;
  return (
    <Paper
      id={"Rooms"}
      style={{
        padding: "1rem",
        marginTop: "1rem",
      }}
      elevation={2}
    >
      <Typography variant="h4" component="h2" gutterBottom textAlign={"center"}>
        <Trans>Our Rooms</Trans>
      </Typography>
      <Grid width={"100%"} container>
        <Grid xs={12} md={6} padding={padding} item>
          <ActionAreaCard
            large={true}
            images={catalog.virsus}
            title={<Trans>Suite no. 1</Trans>}
            description={
              <Trans>
                Introducing our spacious suite at Vila Mares, offering a
                comfortable retreat for your holiday stay. This suite features a
                large balcony where you can relax and soak in the surrounding
                ambiance. Inside, you'll find a well-appointed bathroom and two
                twin beds along with a queen-sized bed, ensuring a restful
                night's sleep for everyone. Additionally, there's a cozy sofa
                for lounging after a day of exploration.
              </Trans>
            }
          />
        </Grid>
        <Grid xs={12} md={6} padding={padding} item>
          <ActionAreaCard
            large={true}
            images={catalog.naujas}
            title={<Trans>Suite no. 2</Trans>}
            description={
              <Trans>
                Introducing our inviting suite at Vila Mares, designed for a
                cozy holiday retreat. This suite boasts a smaller balcony,
                perfect for enjoying a breath of fresh air and a tranquil moment
                outdoors. Inside, you'll discover a spacious living room and
                kitchen area, ideal for gathering with loved ones and enjoying
                home-cooked meals during your stay. The suite features two
                queen-sized beds, ensuring ample space for a restful night's
                sleep, along with a comfortable sofa for relaxation.
              </Trans>
            }
          />
        </Grid>
        <Grid xs={12} md={4} padding={padding} item>
          <ActionAreaCard
            images={catalog.triple}
            title={<Trans>Triple no. 1</Trans>}
            description={
              <Trans>
                Welcome to our cozy triple room located above ground level at
                Vila Mares, offering a comfortable and convenient stay for our
                guests. This room features a queen-size bed and a double bed,
                providing ample space for up to three guests to unwind and
                relax. Thoughtfully designed with your comfort in mind, this
                room ensures a peaceful retreat during your holiday getaway.
                Whether you're traveling with family or friends, our triple room
                offers a charming accommodation option at Vila Mares, perfect
                for your next adventure.
              </Trans>
            }
          />
        </Grid>
        <Grid xs={12} md={4} padding={padding} item>
          <ActionAreaCard
            images={catalog.tripleDown}
            title={<Trans>Triple no. 2</Trans>}
            description={
              <Trans>
                Introducing our cozy triple room nestled slightly below ground
                level at Vila Mares, offering a unique and snug accommodation
                option. This room features a queen-size bed and a double bed,
                providing comfortable sleeping arrangements for up to three
                guests. Despite its subterranean location, the room is
                thoughtfully designed to ensure ample natural light and a
                welcoming ambiance. Perfect for small groups or families seeking
                a peaceful retreat, our triple room offers a tranquil haven for
                your holiday stay at Vila Mares.
              </Trans>
            }
          />
        </Grid>
        <Grid xs={12} md={4} padding={padding} item>
          <ActionAreaCard
            images={catalog.double}
            title={<Trans>Double</Trans>}
            description={
              <Trans>
                This cozy double room features a comfortable, inviting space
                complete with a modern, en-suite shower. A large window floods
                the room with natural light, offering a bright and airy
                ambiance. Ideal for relaxation and privacy, the room provides a
                serene retreat for guests
              </Trans>
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Rooms;
