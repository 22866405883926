import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CardActionArea from "@mui/material/CardActionArea";
import Paper from "@mui/material/Paper";
import catalog from "../../img/images";
import { useGlobalStore } from "../../store/store";
function Image({ images, index }: { images: string[]; index: number }) {
  const openImageViewer = useGlobalStore((state) => state.openImageViewer);
  return (
    <CardActionArea
      onClick={() => {
        openImageViewer(images, index);
      }}
      style={{
        display: "flex",
        backgroundImage: `url(${images[index]})`,
        backgroundSize: "cover",
        height: "100%",
        width: "100%",
        aspectRatio: "16/9",
      }}
    ></CardActionArea>
  );
}
export default function ImageGrid() {
  return (
    <Paper
      style={{
        padding: "1.5rem",
        margin: "1rem 0",
      }}
      elevation={4}
    >
      <Grid container spacing={1}>
        <Grid xs={8} md={2}>
          <Image images={catalog.laukas} index={0} />
        </Grid>
        <Grid xs={4} md={6}>
          <Image images={catalog.laukas} index={1} />
        </Grid>
        <Grid xs={4} md={4}>
          <Image images={catalog.laukas} index={2} />
        </Grid>
        <Grid xs={8} md={4}>
          <Image images={catalog.laukas} index={6} />
        </Grid>
        <Grid xs={8} md={6}>
          <Image images={catalog.laukas} index={3} />
        </Grid>
        <Grid xs={4} md={2}>
          <Image images={catalog.laukas} index={4} />
        </Grid>
      </Grid>
    </Paper>
  );
}
