// export default {
//   double: [
//     "img/double/1-min.jpg",
//     "img/double/2-min.jpg",
//     "img/double/3-min.jpg",
//   ],
//   triple: [
//     "img/triplev/1-min.jpg",
//     "img/triplev/2-min.jpg",
//     "img/triplev/3-min.jpg",
//   ],
//   tripleDown: [
//     "img/triplea/1-min.jpg",
//     "img/triplea/2-min.jpg",
//     "img/triplea/3-min.jpg",
//     "img/triplea/4-min.jpg",
//     "img/triplea/5-min.jpg",
//     "img/triplea/6-min.jpg",
//   ],
//   naujas: [
//     "img/fiven/1-min.jpg",
//     "img/fiven/2-min.jpg",
//     "img/fiven/3-min.jpg",
//     "img/fiven/4-min.jpg",
//     "img/fiven/5-min.jpg",
//     "img/fiven/6-min.jpg",
//     "img/fiven/7-min.jpg",
//     "img/fiven/8-min.jpg",
//     "img/fiven/9-min.jpg",
//   ],
//   virsus: [
//     "img/fivev/1-min.jpg",
//     "img/fivev/2-min.jpg",
//     "img/fivev/3-min.jpg",
//     "img/fivev/4-min.jpg",
//     "img/fivev/5-min.jpg",
//     "img/fivev/6-min.jpg",
//     "img/fivev/7-min.jpg",
//     "img/fivev/8-min.jpg",
//   ],
//   laukas: [
//     "img/outside/1.jpg",
//     "img/outside/2.jpg",
//     "img/outside/3.jpg",
//     "img/outside/4.jpg",
//     "img/outside/5.jpg",
//     "img/outside/6.jpg",
//     "img/outside/7.jpg",
//     "img/outside/8.jpg",
//     "img/outside/9.jpg",
//     "img/outside/10.jpg",
//     "img/outside/11.jpg",
//     "img/outside/12.jpg",
//     "img/outside/13.jpg",
//     "img/outside/14.jpg",
//   ],
// };
import double1 from "./double/1-min.jpg";
import double2 from "./double/2-min.jpg";
import double3 from "./double/3-min.jpg";
import triplev1 from "./triplev/1-min.jpg";
import triplev2 from "./triplev/2-min.jpg";
import triplev3 from "./triplev/3-min.jpg";
import triplea1 from "./triplea/1-min.jpg";
import triplea2 from "./triplea/2-min.jpg";
import triplea3 from "./triplea/3-min.jpg";
import triplea4 from "./triplea/4-min.jpg";
import triplea5 from "./triplea/5-min.jpg";
import triplea6 from "./triplea/6-min.jpg";
import fiven1 from "./fiven/1-min.jpg";
import fiven2 from "./fiven/2-min.jpg";
import fiven3 from "./fiven/3-min.jpg";
import fiven4 from "./fiven/4-min.jpg";
import fiven5 from "./fiven/5-min.jpg";
import fiven6 from "./fiven/6-min.jpg";
import fiven7 from "./fiven/7-min.jpg";
import fiven8 from "./fiven/8-min.jpg";
import fivev1 from "./fivev/1-min.jpg";
import fivev2 from "./fivev/2-min.jpg";
import fivev3 from "./fivev/3-min.jpg";
import fivev4 from "./fivev/4-min.jpg";
import fivev5 from "./fivev/5-min.jpg";
import fivev6 from "./fivev/6-min.jpg";
import fivev7 from "./fivev/7-min.jpg";
import fivev8 from "./fivev/8-min.jpg";
import outside1 from "./outside/1.jpg";
import outside2 from "./outside/2.jpg";
import outside3 from "./outside/3.jpg";
import outside4 from "./outside/4.jpg";
import outside5 from "./outside/5.jpg";
import outside6 from "./outside/6.jpg";
import outside7 from "./outside/7.jpg";
import outside8 from "./outside/8.jpg";
import outside9 from "./outside/9.jpg";
import outside10 from "./outside/10.jpg";
import outside11 from "./outside/11.jpg";
import outside12 from "./outside/12.jpg";
import outside13 from "./outside/13.jpg";
import outside14 from "./outside/14.jpg";
export default {
  double: [double1, double2, double3],
  triple: [triplev1, triplev2, triplev3],
  tripleDown: [triplea1, triplea2, triplea3, triplea4, triplea5, triplea6],
  naujas: [fiven1, fiven2, fiven3, fiven4, fiven5, fiven6, fiven7, fiven8],
  virsus: [fivev1, fivev2, fivev3, fivev4, fivev5, fivev6, fivev7, fivev8],
  laukas: [
    outside1,
    outside2,
    outside3,
    outside4,
    outside5,
    outside6,
    outside7,
    outside8,
    outside9,
    outside10,
    outside11,
    outside12,
    outside13,
    outside14,
  ],
};
