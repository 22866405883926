import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Trans } from "@lingui/macro";

function About() {
  return (
    <Paper
      id="About"
      style={{
        padding: "1rem",
        marginBottom: "1rem",
      }}
      elevation={1}
    >
      <Box maxWidth={800} margin={"0 auto"} textAlign={"center"}>
        <Typography variant="h4" component="h2" gutterBottom>
          <Trans>About us</Trans>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Trans>
            Vila Mares stands out with its architecture. It is situated on a
            footpath instead of a street, therefore, it is quiet.
          </Trans>
        </Typography>

        <Typography variant="body1" gutterBottom>
          <Trans>
            Every bed in the vila is comfortably soft and made by <b>JANSEN</b>.
            The linen is made by a Swedish brand <b>HEMTEX</b>. Both compatible
            with the OEKO-TEX Standard 100.
          </Trans>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Trans>
            We offer five rooms, each boasting its own separate entrance from
            the outside. Every room or suite is equipped with a private bathroom
            and kitchen facilities. While our suites feature large kitchens, our
            cozy triple and double rooms offer convenient mini-kitchens. Guests
            are welcome to enjoy complimentary amenities such as bicycles,
            barbecue facilities, parking space, and WiFi. For those traveling
            with pets, a daily fee applies, ranging from 3 to 10 Euros depending
            on the size of the animal.
          </Trans>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Trans>
            Located just 400 meters from the central street, 1.3 kilometers from
            the Baltic Sea coastline, and 600 meters from the renowned
            Tiskevičius Palace park, Vila Mares offers both convenience and
            tranquility. Additionally, guests will find an electric car charging
            station nearby.
          </Trans>
        </Typography>
      </Box>
    </Paper>
  );
}

export default About;
