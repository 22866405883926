import { useEffect, useState } from "react";
import ThemeProviderFix from "./ThemeProviderFix";
import Hero from "./components/hero/Hero";
import ImageGrid from "./components/image-list-area/ImageGrid";
import AppBarTop from "./components/app-bar/AppBarTop";
import SliderFullScreen from "./components/mui-image-slider/SliderFullScreen";
import { useGlobalStore } from "./store/store";
import Rooms from "./components/rooms/Rooms";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
function VilaMares() {
  const modalImages = useGlobalStore((state) => state.modalState);
  const closeImageViewer = useGlobalStore((state) => state.closeImageViewer);
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (event.state && event.state.imageViewer) {
        closeImageViewer();
      }
    };
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeImageViewer();
      }
    };
    window.addEventListener("popstate", handlePopState);
    document.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);
  useEffect(() => {
    // Function to prevent default behavior
    const preventScroll = (e: Event) => e.preventDefault();

    if (modalImages) {
      // Add both listeners when the overlay is open
      window.addEventListener("touchmove", preventScroll, { passive: false });
      window.addEventListener("wheel", preventScroll, { passive: false });
    } else {
      // Remove listeners when the overlay is closed
      window.removeEventListener("touchmove", preventScroll);
      window.removeEventListener("wheel", preventScroll);
    }

    // Cleanup function to remove listeners when the component unmounts
    return () => {
      window.removeEventListener("touchmove", preventScroll);
      window.removeEventListener("wheel", preventScroll);
    };
  }, [modalImages]);
  return (
    <ThemeProviderFix>
      <Hero />
      <div
        className="App"
        style={{
          display: "flex",
          maxWidth: "1600px",
          margin: "auto",
          flexDirection: "column",
        }}
      >
        {modalImages && <SliderFullScreen />}
        <AppBarTop />
        {/* ImageList component showing a grid of images. Click an image to go into a full screen slider */}
        {/* Rooms area - shows cards for all rooms with small description */}
        {/* About area - shows the common benefits of the property */}
        {/* Contact area - show email, send email link (maybe), phone and and a form to send an inquiry  */}
        {/* Footer area showing the pretty map */}
        <Rooms />
        <ImageGrid />
        <About />
        <Contact />
        <Paper
          style={{
            borderRadius: "0",
          }}
        >
          <Typography
            textAlign="center"
            variant="body2"
            gutterBottom
            padding={2}
          >
            2024 Vila Mares. All rights reserved.
          </Typography>
        </Paper>
      </div>
    </ThemeProviderFix>
  );
}

export default VilaMares;
