import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Fab from "@mui/material/Fab";
import { ChevronLeft, ChevronRight, X } from "lucide-react";
import { useGlobalStore } from "../../store/store";
import useMediaQuery from "@mui/material/useMediaQuery";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
function SliderFullScreen() {
  const matches = useMediaQuery("(max-width:800px)");
  const modalState = useGlobalStore((state) => state.modalState);
  const closeImageViewer = useGlobalStore((state) => state.closeImageViewer);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(modalState!.initialIndex);
  const maxSteps = modalState!.images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  React.useEffect(() => {
    const handleKeys = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        handleBack();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };
    document.addEventListener("keydown", handleKeys);
    return () => {
      document.removeEventListener("keydown", handleKeys);
    };
  }, []);
  return (
    <div
      onClick={closeImageViewer}
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        width: "100vw",
        height: "100%",
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0",
        zIndex: 9000,
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100vw",
          maxWidth: "1600px",
          height: matches ? "60vh" : "80vh",
          zIndex: 8000,
        }}
      >
        <AutoPlaySwipeableViews
          interval={100000}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {modalState!.images.map((step, index) => (
            <div key={step}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    objectFit: "contain",
                    objectPosition: "center",
                    display: "block",

                    height: matches ? "60vh" : "80vh",
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step}
                  alt={"image"}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        {!matches && (
          <>
            <Fab
              onClick={handleBack}
              size="medium"
              style={{
                backgroundColor: "var(--gray-700)",
                left: 30,
                top: "50%",
                transform: "translateY(-50%)",
                position: "absolute",
              }}
              aria-label="previous"
            >
              <ChevronLeft
                style={{
                  stroke: "var(--gray-100)",
                }}
              />
            </Fab>
            <Fab
              onClick={handleNext}
              size="medium"
              style={{
                backgroundColor: "var(--gray-700)",
                right: 30,
                top: "50%",
                transform: "translateY(-50%)",
                position: "absolute",
              }}
              aria-label="next"
            >
              <ChevronRight
                style={{
                  stroke: "var(--gray-100)",
                }}
              />
            </Fab>
          </>
        )}

        <X
          onClick={closeImageViewer}
          style={{
            height: 30,
            backgroundColor: "transparent",
            color: "var(--gray-100)",
            right: 30,
            top: matches ? "25vh" : "15vh",
            position: "absolute",
          }}
          aria-label="next"
        />
        <div
          style={{
            position: "absolute",
            bottom: matches ? "23vh" : "15vh",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <MobileStepper
            variant="dots"
            steps={modalState!.images.length}
            position="static"
            activeStep={activeStep}
            sx={{
              maxWidth: 400,
              flexGrow: 1,
              borderRadius: "1rem",
              backgroundColor: "rgba(163, 163, 163, 0.4)",
            }}
            backButton={undefined}
            nextButton={undefined}
          />
        </div>
      </div>
    </div>
  );
}

export default SliderFullScreen;
