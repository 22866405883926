import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import CardActionArea from "@mui/material/CardActionArea/CardActionArea";
import { useGlobalStore } from "../../store/store";
import { Trans } from "@lingui/macro";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
type SliderProps = {
  images: string[];
  large?: boolean;
};

function Slider({ images, large }: SliderProps) {
  const openImageViewer = useGlobalStore((state) => state.openImageViewer);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
      <Paper elevation={1}>
        <AutoPlaySwipeableViews
          interval={10000}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step}>
              {Math.abs(activeStep - index) <= 2 ? (
                <CardActionArea
                  onClick={() => {
                    openImageViewer(images, index);
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      objectFit: "cover",
                      margin: "auto",
                      height: large ? 350 : 255,
                      display: "block",
                      maxWidth: 400,
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step}
                    alt={"image"}
                  />
                </CardActionArea>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </Paper>
      <Paper elevation={1}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <Trans>Next</Trans>
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              <Trans>Back</Trans>
            </Button>
          }
        />
      </Paper>
    </Box>
  );
}

export default Slider;
