import { Trans } from "@lingui/macro";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Paper from "@mui/material/Paper";

function Contact() {
  const matches = useMediaQuery("(max-width:800px)");
  return (
    <Paper
      id="Contact"
      style={{
        padding: "1rem",
        width: "auto",
        borderRadius: "0",
        display: "flex",
      }}
      elevation={2}
    >
      <Card
        style={{
          width: matches ? "100%" : undefined,
          margin: matches ? "0" : "auto",
          padding: "1rem",
        }}
        variant="outlined"
      >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <Trans>Reach out to us</Trans>
          </Typography>
          <Typography variant="h5" component="div">
            VILA.MARES@GMAIL.COM
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            +370 686 10005
          </Typography>
          <Typography variant="body2">
            Ramybes g. 21, Palanaga, LT-00132
          </Typography>
        </CardContent>

        <CardActions>
          {matches && (
            <Button href="mailto:vila.mares@gmail.com" size="small">
              <Trans>Email now</Trans>
            </Button>
          )}
          <Button
            target="_blank"
            href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x46e5292e56550ee3:0xec7dea545e3f5607?sa=X&ved=1t:8290&ictx=111"
            size="small"
          >
            <Trans>Open in Google Maps</Trans>
          </Button>
        </CardActions>
      </Card>
    </Paper>
  );
}

export default Contact;
